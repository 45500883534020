import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en_US } from './locales/en/en_US';
import { es_MX } from './locales/es/es_MX';

const resources = {
  us: {
    translation: en_US,
  },
  es: {
    translation: es_MX,
  },
};

export const availableLanguages = Object.keys(resources);

const lng = localStorage.getItem('lng') ?? 'us'; 
i18n.use(initReactI18next).init({
  fallbackLng: lng,
  lng: lng,
  resources,
});

export default i18n;