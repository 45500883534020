import {
  GridItem,
  Text,
  SimpleGrid,
  Box,
  Image,
  useColorModeValue,
  Stack,
  List,
  ListIcon,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Link,
  TableCaption,
} from "@chakra-ui/react";
import Layout from "../components/Layout";
import "@fontsource/inter";
import PreviewImg from "../assets/images/preview.gif";
import RewardsImg from "../assets/images/rewards.png";
import RoadmapImg from "../assets/images/roadmap.jpg";
import RoadmapUSImg from "../assets/images/roadmap_us.jpg";
import ToolBar from "../components/Toolbar";
import { CheckCircleIcon, TimeIcon } from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t, i18n } = useTranslation();
  console.log(i18n.language);
  return (
    <>
      <Layout className="principalHome3">
        <ToolBar />
        <SimpleGrid color="black" columns={[10, 6, 10, 10]} width="100%">
          <GridItem colStart={[2, 2, 3, 3]} colSpan={[8, 4, 3, 3]}>
            <Box paddingTop={"30px"}>
              <Text
                display={["flex", "flex", "flex", "flex"]}
                fontSize={[26, 24, 36, 48]}
                fontWeight="900"
                color="white"
              >
                {t('home.title')}
              </Text>
              <Text fontSize={[14, 12, 18, 18]} color="bisque">
                {t('home.subtitle')}
              </Text>
            </Box>
            <Box marginTop={"20px"} display={["flex", "flex", "flex", "flex"]} justifyContent={["center", "center", "left", "left"]}>
              <Link href='https://t.me/bananaClubOfficial' isExternal marginRight="10px">
                <Box width='55px' cursor='pointer' className="icon-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" enableBackground="new 0 0 500 500"><path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z" /></svg>
                </Box>
              </Link>
              <Link href='https://twitter.com/BananaClubOk' isExternal>
                <Box width="50px" cursor="pointer" className="icon-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z" /></svg>
                </Box>
              </Link>
            </Box>
          </GridItem>
          <GridItem
            marginLeft={["0px", "0px", "10px", "10px"]}
            marginTop={["30px", "30px", null, null]}
            colStart={[3, 2, null, null]}
            colSpan={[6, 4, 3, 3]}
            textAlign="right"
            fontSize={[12, 14, 16, 16]}
            display="flex"
            justifyContent={["center", "center", "right", "right"]}
          >
            <Box
              maxW={"350px"}
              w={"full"}
              bg={useColorModeValue("white", "gray.800")}
              boxShadow={"2xl"}
              rounded={"md"}
              overflow={"hidden"}
              border={`5px solid 'gray'`}
              position="relative"
            >
              <Box
                width={"90%"}
                justifyItems={"center"}
                alignItems={"center"}
                margin={"0 auto"}
                padding={"10px 15px"}
                position={"relative"}
                border-radius={"15px"}
              >
                <Image
                  w={"full"}
                  src={PreviewImg}
                  objectFit={"cover"}
                  borderRadius={"15px"}
                />
              </Box>
              <Box p={6}>
                <Stack direction={"row"} justify={"center"} spacing={10}>
                  <Stack spacing={0} align={"center"}>
                    <Text fontWeight={600} fontSize={[20, 24, 30, 30]}>
                      5k
                    </Text>
                    <Text fontSize={"sm"} color={"gray.500"} textAlign="center">
                      NFTs
                    </Text>
                  </Stack>
                  <Stack spacing={0} align={"center"}>
                    <Text fontWeight={600} fontSize={[20, 24, 30, 30]}>
                      +35k
                    </Text>
                    <Text fontSize={"sm"} color={"gray.500"} textAlign="center">
                      {t('combinations')}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </GridItem>
        </SimpleGrid>
      </Layout>
      <Layout className="principalHome2">
        <SimpleGrid color="black" columns={[6, 6, 8, 8]} width="100%" id="rewards">
          <GridItem
            colStart={[2, 2, 2, 2]}
            colSpan={[4, 4, 6, 6]}
            textAlign="center"
          >
            <Box paddingTop={"30px"}>
              <Text fontSize={[26, 24, 36, 48]} fontWeight="900">
                {t('toolbar.rewards')}
              </Text>
            </Box>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid color="black" columns={[6, 6, 10, 10]} width="100%">
          <GridItem colStart={[2, 2, 3, 3]} colSpan={[4, 4, 3, 3]} display={["none", "none", "flex", "flex"]}>
            <Box
              maxW={"350px"}
              w={"full"}
              boxShadow={"2xl"}
              rounded={"md"}
              overflow={"hidden"}
              border={`5px solid 'gray'`}
              position="relative"
            >
              <Image
                w={"full"}
                src={RewardsImg}
                objectFit={"cover"}
                borderRadius={"15px"}
              />
            </Box>
          </GridItem>
          <GridItem
            colStart={[2, 2, null, null]}
            colSpan={[4, 4, 3, 3]}
            textAlign="left"
            fontSize={[12, 14, 16, 16]}
            display="flex"
            justifyContent="left"
            marginTop={["30px", "30px", null, null]}
          >
            <Box>
              <Text fontSize={[16, 16, 26, 26]} color="bisque">
                {t('rewards.title')}
                <List spacing={3} marginTop="20px" fontSize={[12, 12, 22, 22]}>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color='green.500' />
                    {t('rewards.list1')}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color='green.500' />
                    {t('rewards.list2')}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color='green.500' />
                    {t('rewards.list3')}
                  </ListItem>
                  <ListItem>
                    <ListIcon as={TimeIcon} color='green.500' />
                    {t('rewards.list4')}
                  </ListItem>
                </List>
              </Text>
            </Box>
          </GridItem>
          <GridItem colStart={[2, 2, 3, 3]} colSpan={[4, 4, 3, 3]} display={["flex", "flex", "none", "none"]} marginTop="20px">
            <Box
              maxW={"350px"}
              w={"full"}
              boxShadow={"2xl"}
              rounded={"md"}
              overflow={"hidden"}
              border={`5px solid 'gray'`}
              position="relative"
            >
              <Image
                w={"full"}
                src={RewardsImg}
                objectFit={"cover"}
                borderRadius={"15px"}
              />
            </Box>
          </GridItem>
        </SimpleGrid>
      </Layout>
      <Layout className="principalHome3">
        <SimpleGrid color="black" columns={[6, 6, 8, 8]} width="100%" id="batch">
          <GridItem
            colStart={[2, 2, 2, 2]}
            colSpan={[4, 4, 6, 6]}
            textAlign="center"
          >
            <Box paddingTop={"30px"}>
              <Text fontSize={[26, 24, 36, 48]} fontWeight="900">
                {t('toolbar.batch')}
              </Text>
            </Box>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid color="black" columns={[10, 10, 16, 16, 16]} width="100%">
          <GridItem colStart={[2, 2, 2, 4, 4]} colSpan={[8, 8, 4, 4, 3]} textAlign="justify">
            <Box>
              <Text fontSize={[16, 16, 18, 18]} color="bisque">
                <p>{t('batch.text1')}</p><br />
                <p>{t('batch.text2')}</p><br />
                <p>{t('batch.text3')}</p>
              </Text>
            </Box>
          </GridItem>
          <GridItem
            colStart={[2, 2, null, null, null]}
            colSpan={[8, 8, 9, 6, 7]}
            textAlign="left"
            fontSize={[12, 14, 16, 16]}
            display="flex"
            justifyContent="left"
            marginTop={["30px", "30px", "0px", "0px"]}
            marginLeft={["0px", "0px", "30px", "30px"]}
          >
            <TableContainer width={'100%'}>
              <Table>
                <TableCaption color={'white'}>{t('table.notes')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('table.mint')}</Th>
                    <Th color='white'>{t('table.price')}</Th>
                    <Th color='white'>{t('table.draw2')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-500</Td>
                    <Td>0.150 BNB</Td>
                    <Td isNumeric>~40 BNB<sup>*</sup></Td>
                  </Tr>
                  <Tr>
                    <Td>501-1500</Td>
                    <Td>0.130 BNB</Td>
                    <Td isNumeric>~78 BNB<sup>*</sup></Td>
                  </Tr>
                  <Tr>
                    <Td>1501-3000</Td>
                    <Td>0.108 BNB</Td>
                    <Td isNumeric>~110 BNB<sup>*</sup></Td>
                  </Tr>
                  <Tr>
                    <Td>3001-5000</Td>
                    <Td>0.087 BNB</Td>
                    <Td isNumeric>~130 BNB<sup>*</sup></Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('table.mint')}</Th>
                    <Th color='white'>{t('table.price')}</Th>
                    <Th color='white'>{t('table.draw2')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
        </SimpleGrid>
        {/* Batch 1 */}
        <SimpleGrid color="black" columns={[6, 6, 8, 8]} width="100%" id="batch">
          <GridItem
            colStart={[2, 2, 2, 2]}
            colSpan={[4, 4, 6, 6]}
            textAlign="center"
          >
            <Box paddingTop={"30px"}>
              <Text fontSize={[26, 24, 36, 48]} fontWeight="900">
                Batch 1
              </Text>
            </Box>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid color="black" columns={[10, 10, 16, 16, 16]} width="100%">
          <GridItem colStart={[2, 2, 2, 4, 4]} colSpan={[8, 8, 7, 5, 5]} textAlign="justify">
            <TableContainer width={'100%'}>
              <Table>
                <TableCaption color={'white'} placement="top" fontSize="18px">{t('table.draw')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-5</Td>
                    <Td>~0.36 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>6-15</Td>
                    <Td>~0.28 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>16-35</Td>
                    <Td>~0.23 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>36-75</Td>
                    <Td>~0.16 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>76-150</Td>
                    <Td>~0.13 BNB</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
          <GridItem
            colStart={[2, 2, null, null, null]}
            colSpan={[8, 8, 7, 5, 5]}
            display="flex"
            marginTop={["30px", "30px", "0px", "0px"]}
            marginLeft={["0px", "0px", "30px", "30px"]}
          >
            <TableContainer width={'100%'}>
              <Table>
              <TableCaption color={'white'} placement="top" fontSize="18px">Airdrop Token</TableCaption>
              <TableCaption color={'white'} fontSize="14px">{t('table.notes2')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-20</Td>
                    <Td>~0.073 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>21-55</Td>
                    <Td>~0.062 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>56-120</Td>
                    <Td>~0.045 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>121-250</Td>
                    <Td>~0.028 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>251-500</Td>
                    <Td>~0.017 BNB</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
        </SimpleGrid>
        {/* Batch 2 */}
        <SimpleGrid color="black" columns={[6, 6, 8, 8]} width="100%" id="batch">
          <GridItem
            colStart={[2, 2, 2, 2]}
            colSpan={[4, 4, 6, 6]}
            textAlign="center"
          >
            <Box paddingTop={"30px"}>
              <Text fontSize={[26, 24, 36, 48]} fontWeight="900">
                Batch 2
              </Text>
            </Box>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid color="black" columns={[10, 10, 16, 16, 16]} width="100%">
          <GridItem colStart={[2, 2, 2, 4, 4]} colSpan={[8, 8, 7, 5, 5]} textAlign="justify">
            <TableContainer width={'100%'}>
              <Table>
                <TableCaption color={'white'} placement="top" fontSize="18px">{t('table.draw')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-10</Td>
                    <Td>~0.31 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>11-30</Td>
                    <Td>~0.20 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>31-80</Td>
                    <Td>~0.15 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>81-160</Td>
                    <Td>~0.13 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>161-290</Td>
                    <Td>~0.10 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>291-450</Td>
                    <Td>~0.08 BNB</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
          <GridItem
            colStart={[2, 2, null, null, null]}
            colSpan={[8, 8, 7, 5, 5]}
            display="flex"
            marginTop={["30px", "30px", "0px", "0px"]}
            marginLeft={["0px", "0px", "30px", "30px"]}
          >
            <TableContainer width={'100%'}>
              <Table>
              <TableCaption color={'white'} placement="top" fontSize="18px">Airdrop Token</TableCaption>
              <TableCaption color={'white'} fontSize="14px">{t('table.notes2')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-15</Td>
                    <Td>~0.081 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>16-55</Td>
                    <Td>~0.044 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>56-145</Td>
                    <Td>~0.030 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>146-300</Td>
                    <Td>~0.021 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>301-500</Td>
                    <Td>~0.019 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>501-750</Td>
                    <Td>~0.017 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>751-1100</Td>
                    <Td>~0.014 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>1101-1500</Td>
                    <Td>~0.012 BNB</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
        </SimpleGrid>
        {/* Batch 3 */}
        <SimpleGrid color="black" columns={[6, 6, 8, 8]} width="100%" id="batch">
          <GridItem
            colStart={[2, 2, 2, 2]}
            colSpan={[4, 4, 6, 6]}
            textAlign="center"
          >
            <Box paddingTop={"30px"}>
              <Text fontSize={[26, 24, 36, 48]} fontWeight="900">
                Batch 3
              </Text>
            </Box>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid color="black" columns={[10, 10, 16, 16, 16]} width="100%">
          <GridItem colStart={[2, 2, 2, 4, 4]} colSpan={[8, 8, 7, 5, 5]} textAlign="justify">
            <TableContainer width={'100%'}>
              <Table>
                <TableCaption color={'white'} placement="top" fontSize="18px">{t('table.draw')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-20</Td>
                    <Td>~0.24 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>21-60</Td>
                    <Td>~0.18 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>61-140</Td>
                    <Td>~0.13 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>141-300</Td>
                    <Td>~0.10 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>301-520</Td>
                    <Td>~0.08 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>521-900</Td>
                    <Td>~0.06 BNB</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
          <GridItem
            colStart={[2, 2, null, null, null]}
            colSpan={[8, 8, 7, 5, 5]}
            display="flex"
            marginTop={["30px", "30px", "0px", "0px"]}
            marginLeft={["0px", "0px", "30px", "30px"]}
          >
            <TableContainer width={'100%'}>
              <Table>
              <TableCaption color={'white'} placement="top" fontSize="18px">Airdrop Token</TableCaption>
              <TableCaption color={'white'} fontSize="14px">{t('table.notes2')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-15</Td>
                    <Td>~0.04914 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>16-55</Td>
                    <Td>~0.03544 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>56-150</Td>
                    <Td>~0.02387 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>151-300</Td>
                    <Td>~0.01701 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>301-500</Td>
                    <Td>~0.01418 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>501-750</Td>
                    <Td>~0.01247 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>751-1100</Td>
                    <Td>~0.01004 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>1101-1500</Td>
                    <Td>~0.00851 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>1501-1950</Td>
                    <Td>~0.00630 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>1951-2450</Td>
                    <Td>~0.00567 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>2451-3000</Td>
                    <Td>~0.00515 BNB</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
        </SimpleGrid>
        {/* Batch 4 */}
        <SimpleGrid color="black" columns={[6, 6, 8, 8]} width="100%" id="batch">
          <GridItem
            colStart={[2, 2, 2, 2]}
            colSpan={[4, 4, 6, 6]}
            textAlign="center"
          >
            <Box paddingTop={"30px"}>
              <Text fontSize={[26, 24, 36, 48]} fontWeight="900">
                Batch 4
              </Text>
            </Box>
          </GridItem>
        </SimpleGrid>
        <SimpleGrid color="black" columns={[10, 10, 16, 16, 16]} width="100%">
          <GridItem colStart={[2, 2, 2, 4, 4]} colSpan={[8, 8, 7, 5, 5]} textAlign="justify">
            <TableContainer width={'100%'}>
              <Table>
                <TableCaption color={'white'} placement="top" fontSize="18px">{t('table.draw')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-30</Td>
                    <Td>~0.1276 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>31-90</Td>
                    <Td>~0.1117 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>91-190</Td>
                    <Td>~0.0957 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>191-360</Td>
                    <Td>~0.0788 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>361-560</Td>
                    <Td>~0.0718 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>561-810</Td>
                    <Td>~0.0612 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>811-1130</Td>
                    <Td>~0.0538 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>1131-1500</Td>
                    <Td>~0.0414 BNB</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
          <GridItem
            colStart={[2, 2, null, null, null]}
            colSpan={[8, 8, 7, 5, 5]}
            display="flex"
            marginTop={["30px", "30px", "0px", "0px"]}
            marginLeft={["0px", "0px", "30px", "30px"]}
          >
            <TableContainer width={'100%'}>
              <Table>
              <TableCaption color={'white'} placement="top" fontSize="18px">Airdrop Token</TableCaption>
              <TableCaption color={'white'} fontSize="14px">{t('table.notes2')}</TableCaption>
                <Thead color='white'>
                  <Tr >
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>1-10</Td>
                    <Td>~0.05285 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>11-50</Td>
                    <Td>~0.03964 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>51-150</Td>
                    <Td>~0.01938 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>151-300</Td>
                    <Td>~0.01292 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>301-500</Td>
                    <Td>~0.01145 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>501-750</Td>
                    <Td>~0.01268 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>751-1100</Td>
                    <Td>~0.00805 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>1101-1500</Td>
                    <Td>~0.00661 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>1501-1950</Td>
                    <Td>~0.00626 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>1951-2450</Td>
                    <Td>~0.00599 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>2451-3000</Td>
                    <Td>~0.00577 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>3001-3600</Td>
                    <Td>~0.00529 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>3601-4250</Td>
                    <Td>~0.00488 BNB</Td>
                  </Tr>
                  <Tr>
                    <Td>4251-5000</Td>
                    <Td>~0.00399 BNB</Td>
                  </Tr>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th color='white'>{t('batch.titlePrize')}</Th>
                    <Th color='white'>{t('batch.amount')}</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          </GridItem>
        </SimpleGrid>
      </Layout>
      <Layout className="principalHome2">
        <SimpleGrid color="black" columns={[6, 6, 10, 10]} width="100%" marginTop='30px' id="roadmap">
          <GridItem colStart={[2, 2, 3, 3]} colSpan={[4, 4, 6, 6]}>
            <Image
              w={"full"}
              src={i18n.language === 'us' ? RoadmapUSImg : RoadmapImg}
              objectFit={"cover"}
              borderRadius={"15px"}
            />
          </GridItem>
        </SimpleGrid>
      </Layout>
    </>
  );
};

export default Home;
