import { configureStore, combineReducers } from '@reduxjs/toolkit'
import counterSlice from './counter';
// ...
const reducer = combineReducers({
    counter: counterSlice,
});
export const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch