export const es_MX = {
  toolbar: {
    rewards: "Recompensas",
    batch: "Batches",
    roadmap: "Roadmap",
  },
  home: {
    title: 'Unete a esta gran experiencia y se parte de Banana Club',
    subtitle: 'Crea, participa & gana varias recompensas.'
  },
  combinations: 'Combinaciones',
  rewards: {
    title: 'Recibe recompensas de diferentes formas:',
    list1: 'Sorteos por cada batch de minteo',
    list2: 'Airdrops de acuerdo a la rareza',
    list3: 'Bonificación por nuevas colecciones',
    list4: 'Y muchas más por venir'
  },
  batch: {
    text1: 'El mint se ejecuta en 4 Batches. Al llegar a la meta de cada Batch se realizara un sorteo en el cual se tendrán grandes premios.',
    text2: 'La comunidad votara por el Token que desee para el Airdrop. Aquellos acreedores de un Banana Club NFT podrán votar.',
    text3: '1 Banana Club NFT = 1 Voto',
    info: 'Tabla de repartición',
    titlePrize: 'Lugares a repartir',
    amount: 'Cantidad'
  },
  table: {
    mint: 'Minteos',
    price: 'Precio Mint',
    draw2: 'Sorteo y Airdrops',
    draw: 'Sorteo',
    notes: '*El valor del premio puede variar de acuerdo al precio de BNB que se tenga al momento del sorteo.',
    notes2: 'El token del airdrop será seleccionado por la comunidad'
  }
};
