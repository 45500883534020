import { NavLink } from "react-router-dom";
import { useDisclosure, Box, Text, Link, IconButton, Flex, SimpleGrid, GridItem } from "@chakra-ui/react";
import ConnectButton from "./ConnectButton";
import LogoSmall from "../assets/images/logosmall.png";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useState } from "react";
import LanguagesButton from "./LanguagesButton";
import { useTranslation } from "react-i18next";

function Toolbar({ full = true }) {
    const { t } = useTranslation();
    const { onOpen } = useDisclosure();
    const [display, changeDisplay] = useState('none')
    return (
        <SimpleGrid columns={[10, 10, 10, 10]} width="100%">
            <GridItem className="navbar" colStart={[3, 3, 3, 3]} colSpan={[3, 3, 3, 3]} display={['none', 'none', 'flex', 'flex']}>
                <Box w='100%'>
                    <NavLink to='/'>
                        <img src={LogoSmall} width="170px" alt="logoimg" />
                    </NavLink>
                </Box>
            </GridItem>
            <GridItem className="navbar" colStart={[2, 2, null, null]} colSpan={[3, 3, 3, 3]} display={['none', 'none', 'flex', 'flex']}>
                <Box w='100%' h='10' justifyContent="end" display="flex">
                    {
                        full && (
                            <>
                                <Text display="flex" alignItems="center" marginRight="15px" color={'white'}>
                                    <Link fontWeight="700" href='#rewards'>
                                        {t('toolbar.rewards')}
                                    </Link>
                                </Text>
                                <Text display="flex" alignItems="center" marginRight="15px" color={'white'}>
                                    <Link fontWeight="700" href='#batch'>
                                        {t('toolbar.batch')}
                                    </Link>
                                </Text>
                                <Text display="flex" alignItems="center" marginRight="15px" color={'white'}>
                                    <Link fontWeight="700" href='#roadmap'>
                                        {t('toolbar.roadmap')}
                                    </Link>
                                </Text>
                            </>
                        )
                    }
                    <LanguagesButton />
                    <ConnectButton handleOpenModal={onOpen} />
                </Box>
            </GridItem>
            <GridItem className="navbar" colStart={[2, 2, null, null]} colSpan={[3, 4, 4, 4]} display={['flex', 'flex', 'none', 'none']}>
                <Box w='100%'>
                    <img src={LogoSmall} width="170px" alt="logoimg" />
                </Box>
                
            </GridItem>
            <GridItem className="navbar" colStart={[9, 9, null, null]} colSpan={[2, 4, 4, 4]} display={['flex', 'flex', 'none', 'none']}>
               
                <IconButton
                    aria-label="Open Menu"
                    size="lg"
                    mr={2}
                    icon={
                        <HamburgerIcon />
                    }
                    onClick={() => changeDisplay('flex')}
                    display={['flex', 'flex', 'none', 'none']}
                />
                <Flex
                    w='100vw'
                    display={display}
                    bgColor="dark"
                    zIndex={20}
                    h="100vh"
                    pos="fixed"
                    top="0"
                    left="0"
                    overflowY="auto"
                    flexDir="column"
                >
                    <Flex justify="flex-end">
                        <IconButton
                            mt={2}
                            mr={2}
                            aria-label="Open Menu"
                            size="lg"
                            icon={
                                <CloseIcon />
                            }
                            onClick={() => changeDisplay('none')}
                        />
                    </Flex>

                    <Flex
                        flexDir="column"
                        align="center"
                    >
                        <ConnectButton handleOpenModal={onOpen} />
                        <Text display="flex" alignItems="center" marginTop="15px">
                            <Link color='white' fontWeight="700" onClick={() => changeDisplay('none')} href='#rewards'>
                                {t('toolbar.rewards')}
                            </Link>
                        </Text>
                        <Text display="flex" alignItems="center" marginTop="15px">
                            <Link color='white' fontWeight="700" onClick={() => changeDisplay('none')} href='#batch'>
                                {t('toolbar.batch')}
                            </Link>
                        </Text>
                        <Text display="flex" alignItems="center" marginTop="15px">
                            <Link color='white' fontWeight="700" onClick={() => changeDisplay('none')} href='#roadmap'>
                                {t('toolbar.roadmap')}
                            </Link>
                        </Text>
                        <LanguagesButton />
                    </Flex>
                </Flex>
            </GridItem>
        </SimpleGrid>

    );
}

export default Toolbar;
